import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue'),
    meta: { title: 'Cyfrowy Dialog' }
  },
  {
    path: '/aktualnosci',
    redirect: { name: 'news' }
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/News'),
    meta: { title: 'News - Cyfrowy Dialog' }
  },
  {
    path: '/co-robimy',
    redirect: { name: 'projects' }
  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import('../views/Projects'),
    meta: { type: 'projects', title: 'Projekty - Cyfrowy Dialog' }
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import('../views/Blog'),
    meta: { title: 'Blog - Cyfrowy Dialog' }
  },
  {
    path: '/blog/p/:p',
    name: 'blog-page',
    component: () => import('../views/Blog')
  },
  {
    path: '/:slug',
    name: 'page',
    component: () => import('../views/Page.vue'),
    meta: {title: 'Cyfrowy Dialog'}
  },
  {
    path: '/news/:slug',
    name: 'single-news',
    component: () => import('../views/Single'),
    meta: { type: 'news', title: 'News' }
  },
  {
    path: '/blog/:slug',
    name: 'single-blog',
    component: () => import('../views/Single'),
    meta: { type: 'blog' }
  },
  {
    path: '/project/:slug',
    name: 'project',
    component: () => import('../views/Project')
  },
  {
    path: '*',
    name: 'not-found',
    component: () => import('../views/Home.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  hash: false,
  routes,
  scrollBehavior: function () {
    return { x: 0, y: 0 }
  }
})

export default router
