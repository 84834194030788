<template>
  <div :class="{'contrast' : $store.state.contrast}" :style="'font-size:' + $store.state.fontsize + 'px'">
    <navigation-bar />
    <main>
      <router-view/>
    </main>
    <page-footer />
  </div>
</template>

<script>
  import NavigationBar from "./components/NavigationBar";
  import PageFooter from "./components/PageFooter";

  export default {
    components: {NavigationBar, PageFooter},
    mounted() {
      this.$store.dispatch('init')
    },
    watch: {
      $route: {
        immediate: true,
        handler(to) {
          document.title = to.meta.title || 'Cyfrowy Dialog';
        }
      },
    }
  }
</script>

<style lang="scss">
  @import "assets/scss/app";
</style>