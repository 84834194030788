<template>
    <div class="contact_card" :class="variant === 'light' ? 'contact_card-light' : 'contact_card-dark'">
        <h6 v-if="position" v-html="position"></h6>
        <h4 v-if="name" v-html="name"></h4>
        <a v-if="address" class="row no-gutters align-items-center" target="_blank" :href="'https://www.google.com/maps/search/?api=1&query=:'+address">
            <b-col cols="auto">
                <icon icon="location" />
            </b-col>
            <b-col>
                {{ address }}
            </b-col>
        </a>
        <a v-if="email" class="row no-gutters align-items-center" :href="'mailto:'+email">
            <b-col cols="auto">
                <icon icon="mail" />
            </b-col>
            <b-col>
                {{ email }}
            </b-col>
        </a>
        <a v-if="phone_number" class="row no-gutters align-items-center" :href="'tel:'+phone_number">
            <b-col cols="auto">
                <icon icon="phone" />
            </b-col>
            <b-col>
                {{ phone_number }}
            </b-col>
        </a>
    </div>
</template>

<script>
    import Icon from './../utility/Icon';

    export default {
        name: "ContactCard",

        components: {
            Icon,
        },

        props: {
            name: String,
            position: String,
            email: String,
            phone_number: String,
            address: String,
            variant: String,
        }
    }
</script>

<style scoped lang="scss">
    .contact_card{
        a{
            margin-bottom: 0.5rem;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.3px;
            color: inherit;

            &:last-child {
                margin-bottom: 0;
            }

            .col:last-child {
                padding-left: 1rem;
            }
        }
    }
    .contact_card-light {
        color: $black;
    }
</style>

<style lang="scss">
    .contact_card{
        .icon{
            display: flex;
            padding: 8px;
            border-radius: 100%;
            background: $primary;

            svg{
                width: 16px;
                height: 16px;
                path{ fill: $white; }
            }
        }
    }
</style>