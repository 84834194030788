<template>
    <div>
        <h5>{{ title }}</h5>
        <ul class="nav_menu_container">
            <li v-for="(item, i) in items" :key="i">
                <a class="h6" :href="item.link.url" :target="item.link.target">{{ item.link.title }}</a>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "NavMenu",
        props: {
            title: String,
            items: Array,
        }
    }
</script>

<style scoped lang="scss">
    .nav_menu_container {
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;

        h5 {
            margin-bottom: 1.5rem;
        }

        li {
            margin-bottom: 0.25rem;

            &:last-child {
                margin-bottom: 0;
            }

            a {
                line-height: 18px;
                color: #DFECFE;
            }
        }
    }
</style>