import Vue from 'vue'
import Vuex from 'vuex'
import ApiClient from './wp_api_client'

const api = new ApiClient(process.env.VUE_APP_API_URL)

Vue.use(Vuex)

function generateGetters(data) {
    let af = {};
    data.map(i => af[i.getterName] = state => slug => state.content[i.varName].filter(p => p.slug === slug)[0])
    return af
}

export default new Vuex.Store({
    state: {
        content: {
            frontpage: null,
            pages: [],
            project: [],
            posts: [],
            news: [],
            tags: [],
            lastPostsQuery: null,
        },
        pagination: {
            posts: 0,
        },
        fontsize: 16,
        nav_menus: [],
        settings: null,
        contrast: false,
    },

    getters: {
        ...generateGetters([
            {getterName: 'news', varName: 'news'},
            {getterName: 'page', varName: 'pages'},
            {getterName: 'project', varName: 'project'},
            {getterName: 'post', varName: 'posts'},
        ]),
        projectById: state => id => {
            const result = state.content.project.find(p => p.id == id)
            return result ? result : state.content.event.find(e => e.id == id)
        },
        newsById: state => id => {
            return state.content.news.find(n => n.id == id)
        },
        blogPostById: state => id => {
            return state.content.posts.find(p => p.id == id)
        },
        menu: state => location => {
            return state.nav_menus.filter(p => p.location === location)[0]
        },
        setting: state => key => {
            if (!state.settings) return
            return state.settings.acf[key]
        }
    },

    mutations: {
        SET_FRONTPAGE(state, payload) {
            state.content.frontpage = payload
        },
        SET_SETTINGS(state, payload) {
            Vue.set(state, 'settings', payload)
        },
        SET_PAGE(state, payload) {
            Vue.set(state.content, 'pages', [payload, ...state.content.pages])
        },
        SET_PROJECT(state, payload) {
            Vue.set(state.content, 'project', [payload, ...state.content.project])
        },
        SET_NEWS(state, payload) {
            Vue.set(state.content, 'news', [payload, ...state.content.news])
        },
        SET_POST(state, payload) {
            Vue.set(state.content, 'posts', [payload, ...state.content.posts])
        },
        SET_POSTS(state, posts) {
            let uniquePosts = posts.filter(p => !state.content.posts.find(cp => cp.id === p.id))
            Vue.set(state.content, 'posts', [...uniquePosts, ...state.content.posts])
        },
        UPDATE_POSTS_PAGE(state, payload) {
            state.pagination.posts = payload.total_pages
            state.content.lastPostsQuery = payload.posts
            let uniquePosts = payload.posts.filter(p => !state.content.posts.find(cp => cp.id === p.id))
            let posts = [...state.content.posts, ...uniquePosts]
            Vue.set(state.content, 'posts', posts.sort((a, b) => new Date(b.date) - new Date(a.date)))
        },
        CLEAR_LAST_QUERY(state) {
            state.content.lastPostsQuery = null
        },
        SET_PAGES(state, payload) {
            Vue.set(state.content, 'pages', payload)
        },
        SET_PROJECTS(state, payload) {
            Vue.set(state.content, 'project', payload)
        },
        SET_ALL_BLOG(state, payload) {
            Vue.set(state.content, 'posts', payload)
        },
        SET_ALL_NEWS(state, payload) {
            Vue.set(state.content, 'news', payload)
        },
        SET_NAV_MENU(state, payload) {
            Vue.set(state, 'nav_menus', [payload, ...state.nav_menus])
        },
        SET_TAGS(state, payload) {
            Vue.set(state.content, 'tags', payload)
        },
        SWITCH_CONTRAST(state) {
            state.contrast = !state.contrast
        },
        INC_FONT(state) {
            if (state.fontsize >= 12 && state.fontsize < 24) {
                state.fontsize += 2
            }
        },
        DEC_FONT(state) {
            if (state.fontsize > 12 && state.fontsize <= 24) {
                state.fontsize -= 2
            }
        },
        RESET_FONT(state) {
            state.fontsize = 16
        }
    },

    actions: {
        init({dispatch}) {
            dispatch('getNavMenu', 'topbar')
            dispatch('getSettings')
        },
        getFrontpage({state}) {
            if (state.content.frontpage) return
            api.getFrontpage()
                .then((response) => {
                    this.commit('SET_FRONTPAGE', response.data)
                })
                .catch((error) => console.log(error))
        },
        getPage({getters, state, dispatch}, slug) {
            if (state.content.pages.length === 0) dispatch('getAllPages');
            else getPost(getters, slug, 'SET_PAGE', 'pages', this)
        },
        getProject({getters}, slug) {
            getPost(getters, slug, 'SET_PROJECT', 'project', this)
        },
        getNews({getters}, slug) {
            getPost(getters, slug, 'SET_NEWS', 'news', this)
        },
        getPost({getters}, slug) {
            getPost(getters, slug, 'SET_POST', 'posts', this)
        },
        getBlogPostById({getters}, id) {
            getPostById(getters, id, 'SET_POST', 'posts', this)
        },
        getProjectById({getters}, id) {
            getPostById(getters, id, 'SET_PROJECT', 'project', this)
        },
        getNewsById({getters}, id) {
            getPostById(getters, id, 'SET_NEWS', 'news', this)
        },
        getFeaturedPosts() {
            api.getFeaturedPosts()
                .then(response => {
                    this.commit('SET_POSTS', response.data)
                })
                .catch()
        },
        getBlogPage(_, payload) {
            this.commit('CLEAR_LAST_QUERY')
            api.getPostPage('posts', payload.page)
                .then(response => {
                    this.commit('UPDATE_POSTS_PAGE', {
                        posts: response.data,
                        total_pages: response.headers['x-wp-totalpages']
                    })
                })
                .catch()
        },
        getAllBlog() {
            getAllPosts('SET_ALL_BLOG', 'posts', this)
        },
        searchBlog(_, search) {
            api.search('posts', search)
                .then(response => {
                    this.commit('SET_POSTS', response)
                })
                .catch()
        },
        getAllNews() {
            getAllPosts('SET_ALL_NEWS', 'news', this)
        },
        getAllPages() {
            getAllPosts('SET_PAGES', 'pages', this)
        },
        getAllProjects() {
            getAllPosts('SET_PROJECTS', 'project', this)
        },
        getTags({state}) {
            if (state.content.tags.length > 0) return
            api.getTags()
                .then((response) => {
                    this.commit('SET_TAGS', response)
                })
                .catch((error) => console.log(error))
        },
        getNavMenu({getters}, location) {
            if (getters.menu(location)) return
            api.getNavMenu(location)
                .then((response) => {
                    this.commit('SET_NAV_MENU', {
                        location: location,
                        items: response.data
                    })
                })
                .catch((error) => console.log(error))
        },
        getSettings({getters}) {
            if (getters.settings) return
            api.getSettings()
                .then((response) => {
                    this.commit('SET_SETTINGS', response.data)
                })
                .catch((error) => console.log(error))
        }
    },

    modules: {}
})

function getPost(getters, slug, mutation, type, _this) {
    if (getters.page(slug)) return
    api.getPost(type, slug)
        .then((response) => {
            _this.commit(mutation, response)
        })
        .catch((error) => console.log(error))
}

function getPostById(getters, id, mutation, type, _this) {
    if (getters.page(id)) return
    api.getPostById(type, id)
        .then((response) => {
            _this.commit(mutation, response)
        })
        .catch((error) => console.log(error))
}

function getAllPosts(mutation, type, _this, page = 1) {
    api.getAllPosts(type, page)
        .then((response) => {
            const totalPages = response.headers["x-wp-totalpages"]
            if (page === 1) {
                _this.commit(mutation, response.data)
            } else {
                _this.commit(mutation, [..._this.state.content[type], ...response.data])
            }
            if (page++ < totalPages) {
                getAllPosts(mutation, type, _this, page++)
            }
        })
        .catch((error) => console.log(error))
}