<template>
    <footer class="page">
        <b-container>
            <b-row>
                <b-col>
                    <img class="logo" src="./../assets/media/logo/white.svg"/>
                </b-col>
            </b-row>
            <b-row class="py-5">
                <b-col lg="5">
                    <h4 v-if="contact" v-html="contact.address"></h4>
                    <a class="h5" v-if="contact" :href="'mailto:'+contact.email">{{ contact.email }}</a>
                    <social-media></social-media>
                </b-col>
                <b-col>
                    <b-row v-if="contact">
                        <b-col class="pt-5 pt-lg-0" v-for="(card, i) in contact.contact_cards" :key="i">
                           <contact-card :name="card.name" :position="card.position" :email="card.email" :phone_number="card.phone_number"></contact-card>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row class="pt-0 pt-md-5">
                <b-col>
                    <hr/>
                </b-col>
            </b-row>
            <b-row class="pt-5 pb-4 pb-lg-7">
                <b-col cols="12" :md="12 / nav_menus.length" class="mb-5 mb-md-0" v-for="(nav, i) in nav_menus" :key="'nav_'+i" >
                    <nav-menu :title="nav.title" :items="nav.links"></nav-menu>
                </b-col>
            </b-row>
            <b-row class="pt-0 pt-lg-4" align-v="center">
                <b-col order="2" order-lg="1" cols="12" lg="auto">
                    <p class="h6 copyright">© Stowarzyszenie Cyfrowy Dialog 2020. Design &amp; code by <a href="https://expansja.pl/" target="_blank">Expansja Advertising</a></p>
                </b-col>
                <b-col order="1" order-lg="2" class="text-lg-right pb-5 pb-lg-0">
                    <ul v-if="docs" class="docs_menu lg-5 mb-lg-0">
                        <li v-for="(doc, i) in ['privacy_policy', 'cookies', 'accessibility']" :key="'doc_'+i">
                            <a class="h6" v-if="docs[doc]" :href="docs[doc].url" :target="docs[doc].target">{{ docs[doc].title }}</a>
                        </li>
                    </ul>
                </b-col>
            </b-row>
            <b-row class="py-5">
                <b-col cols="auto" class="mb-1 mb-md-0">
                    <img src="./../assets/media/cc_logos.png"/>
                </b-col>
                <b-col md="6">
                    <p class="copyright">Wszystkie treści publikowane w serwisie są udostępniane na licencji Creative Commons Uznanie Autorstwa 4.0 Międzynarodowe, o ile nie jest to stwierdzone inaczej</p>
                </b-col>
            </b-row>
        </b-container>
    </footer>
</template>

<script>
    import SocialMedia from "./footer/SocialMedia";
    import ContactCard from "./footer/ContactCard";
    import NavMenu from "./footer/NavMenu";

    export default {
        name: "PageFooter",
        components: {NavMenu, ContactCard, SocialMedia},
        computed: {
            contact() {
                return this.$store.getters.setting('contact')
            },
            nav_menus() {
                return this.$store.getters.setting('nav')
            },
            docs() {
                return this.$store.getters.setting('docs')
            }
        }
    }
</script>

<style lang="scss">
    footer.page{
        padding-top: 80px;
        background: $gray-900;
        color: $white;

        a.h5{ color: $blue-100; }
        .logo{ height: 64px; }

        .copyright{
            &, *{ color: $blue-700; }
            @extend %header-6;
            margin: 0;
        }

        hr {
            margin: 0 auto;
            border-color: $gray-800;
        }

        .docs_menu {
            list-style: none;
            margin-bottom: 0;
            padding-left: 0;
            display: inline-flex;
            flex-direction: column;

            li {
                line-height: 0;
                padding: 0 0 10px;

                &:last-child { padding-bottom: 0; }
                a{ color: $blue-700; }
            }

            @include media-breakpoint-up(lg){
                 flex-direction: row;

                li {
                    border-right: 1px solid $blue-700;
                    padding: 0 1.5rem;

                    &:last-child{ border-right: none; }
                }
            }
        }

        h5{
            margin-bottom: 16px;

            @include media-breakpoint-up(lg){ margin-bottom: 24px; }
        }
    }

    .contrast footer.page{
        background: $black;
        color: $white;

        .copyright{
            &, *{ color: $blue-100; }
        }

        .docs_menu{
            li a{
                color: $blue-100;
            }
        }
    }
</style>
