<template>
    <ul v-if="sm" class="social_media">
        <li v-if="sm.facebook">
            <a :href="sm.facebook" target="_blank">
                <icon icon="facebook" />
            </a>
        </li>
        <li v-if="sm.instagram">
            <a :href="sm.instagram" target="_blank">
                <icon icon="instagram" />
            </a>
        </li>
        <li v-if="sm.twitter">
            <a :href="sm.twitter" target="_blank">
                <icon icon="twitter" />
            </a>
        </li>
        <li v-if="sm.youtube">
            <a :href="sm.youtube" target="_blank">
                <icon icon="youtube" />
            </a>
        </li>
      <li v-if="sm.linkedin">
        <a :href="sm.linkedin" target="_blank">
          <icon icon="linkedin" />
        </a>
      </li>
    </ul>
</template>

<script>
    import Icon from './../utility/Icon';

    export default {
        name: "SocialMedia",

        components: {
            Icon,
        },

        computed: {
            sm() {
                return this.$store.getters.setting('social_media')
            }
        },
    }
</script>

<style scoped lang="scss">
    .social_media {
        margin-top: 1.5rem;
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;

        li {
            display: inline-block;
            margin-right: 1.5rem;

            a{ display: flex; }
            &:last-child { margin-right: 0; }
        }
    }
</style>

<style lang="scss">
    .social_media{
        a{
            svg { width: 24px; }
            svg path{ fill: $white; }
            
            &:hover{
                svg path{ fill: $primary; }
            }
        }
    }
</style>